"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSummaryjsonPath = exports.getVoiceUrl = exports.getJourneyImageUrl = exports.getCollectionImageUrl = exports.getThumbnailImageUrl = exports.getImageUrl = exports.STORAGE_API_URL = exports.MESSAGE_API_URL = exports.ADD_API = exports.AUTH_API = exports.SOCKET_IO_URL = exports.PROD_URL = exports.PROD_SERVER = exports.DEV_SERVER = exports.PROJECT_2025_SHAREID = exports.SUMMARAIZER_ADMIN = exports.DOCS_HOST = exports.APP_HOST = exports.ENV_TYPE = exports.APP_PRODUCT = exports.DEV_MODE = void 0;
const constants_1 = require("./constants");
exports.DEV_MODE = false;
exports.APP_PRODUCT = 'summaraizer';
//possible to have other enviornments later
exports.ENV_TYPE = exports.DEV_MODE ? "dev" : "prod";
exports.APP_HOST = exports.DEV_MODE
    ? 'https://dev.summaraizer.com'
    : 'https://summaraizer.com';
exports.DOCS_HOST = exports.DEV_MODE
    ? 'https://dev-docs.summaraizer.com'
    : 'https://docs.summaraizer.com';
exports.SUMMARAIZER_ADMIN = exports.DEV_MODE ? '6097' : '42259';
exports.PROJECT_2025_SHAREID = exports.DEV_MODE ? 'e2uIC2u8' : 'LLip7sde';
exports.DEV_SERVER = 'https://dev-api.summaraizer.com';
exports.PROD_SERVER = 'https://api.summaraizer.com';
exports.PROD_URL = exports.DEV_MODE
    ? `${exports.DEV_SERVER}/`
    : `${exports.PROD_SERVER}/`;
exports.SOCKET_IO_URL = exports.DEV_MODE
    ? exports.DEV_SERVER
    : exports.PROD_SERVER;
exports.AUTH_API = exports.DEV_MODE
    ? "https://staging-api.soundon.app/"
    : "https://api.soundon.app/";
exports.ADD_API = `${exports.PROD_URL}api/v1/`;
exports.MESSAGE_API_URL = exports.ADD_API;
const STORAGE_SERVER = 'https://d2e4n1tcihf17q.cloudfront.net';
exports.STORAGE_API_URL = exports.DEV_MODE
    ? `${STORAGE_SERVER}/dev/`
    : `${STORAGE_SERVER}/`; //`${characterId}.${character.ext}`
const getImageUrl = (character) => {
    const characterId = typeof character === 'string' ? character : character?.characterId;
    const characterExtension = typeof character !== 'string' && character.extension || 'jpg';
    const imageUrl = `${exports.DEV_MODE
        ? // && character?.primaryCategory === CUSTOM_USER_CATEGORY_NAME
            exports.STORAGE_API_URL
        : exports.STORAGE_API_URL}characters/${characterId}.${characterExtension}`;
    return imageUrl;
};
exports.getImageUrl = getImageUrl;
const getThumbnailImageUrl = (character) => {
    const characterId = typeof character === 'string' ? character : character?.characterId;
    const characterExtension = typeof character !== 'string' && character.extension || 'jpg';
    const imageUrl = `${exports.DEV_MODE
        ? // && character?.primaryCategory === CUSTOM_USER_CATEGORY_NAME
            exports.STORAGE_API_URL
        : exports.STORAGE_API_URL}characters/thumbnails/${characterId}.${characterExtension}`;
    return imageUrl;
};
exports.getThumbnailImageUrl = getThumbnailImageUrl;
const getCollectionImageUrl = (collection) => `${exports.DEV_MODE
    ? // && character?.primaryCategory === CUSTOM_USER_CATEGORY_NAME
        exports.STORAGE_API_URL
    : exports.STORAGE_API_URL}collections/${collection?.collectionId}.${collection?.extension || "jpg"}`;
exports.getCollectionImageUrl = getCollectionImageUrl;
const getJourneyImageUrl = (journey) => `${exports.DEV_MODE
    ? // && character?.primaryCategory === CUSTOM_USER_CATEGORY_NAME
        exports.STORAGE_API_URL
    : exports.STORAGE_API_URL}journeys/${journey?.journeyId}.${journey?.extension || "jpg"}`;
exports.getJourneyImageUrl = getJourneyImageUrl;
const getVoiceUrl = (conversationId, messageId) => `${constants_1.VOICE_CACHE_DIRECTORY_PATH}/${conversationId}-${messageId}.mp3`;
exports.getVoiceUrl = getVoiceUrl;
const getSummaryjsonPath = (userId, conversationId) => (`${exports.STORAGE_API_URL}files/json/${userId}_${conversationId}.json`);
exports.getSummaryjsonPath = getSummaryjsonPath;
