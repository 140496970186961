"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const react_hook_form_1 = require("react-hook-form");
const AppInput_1 = require("../AppInput");
const AppText_1 = require("../AppText");
function FormInput(props) {
    const { field, fieldState } = (0, react_hook_form_1.useController)(props);
    return ((0, jsx_runtime_1.jsxs)(native_base_1.FormControl, { style: styles.formControl, isInvalid: fieldState.invalid, children: [(0, jsx_runtime_1.jsx)(native_base_1.FormControl.Label, { margin: 0, children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.label, children: props.name }) }), (0, jsx_runtime_1.jsx)(AppInput_1.AppInput, { value: field.value, onChangeText: field.onChange, style: styles.input, _stack: { style: styles.inputContainer }, autoComplete: 'off', ...props })] }));
}
exports.default = FormInput;
const styles = react_native_1.StyleSheet.create({
    formControl: {
        marginBottom: 2,
    },
    label: {
        color: 'white',
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 19.36,
        marginLeft: 19.31,
        marginBottom: 13,
    },
    inputContainer: {
        backgroundColor: '#2A2A2F',
    },
    input: {
        backgroundColor: '#2A2A2F',
        color: 'white',
        fontSize: 16,
        lineHeight: 20,
        padding: 12,
    },
});
