"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDevice = void 0;
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_device_info_1 = require("react-native-device-info");
const react_native_2 = require("react-native");
const native_base_1 = require("native-base");
const useiPadOrientation_1 = require("./useiPadOrientation");
const constants_1 = require("../common/constants");
const useDevice = () => {
    const [isTabScreen] = (0, native_base_1.useMediaQuery)({
        minWidth: 768,
        // orientation: "landscape"
    });
    const [isMobileScreen] = (0, native_base_1.useMediaQuery)({
        maxWidth: 767,
    });
    const [isSmallScreen] = (0, native_base_1.useMediaQuery)({
        maxWidth: 430,
    });
    const { orientation } = (0, useiPadOrientation_1.useiPadOrientation)();
    const isDesktopDevice = (0, react_1.useMemo)(() => {
        return react_native_2.Platform.OS === "web" && !(0, react_native_device_info_1.isTablet)();
    }, []);
    const isCompletelyDesktopDevice = (0, react_1.useMemo)(() => {
        return react_native_2.Platform.OS === "web" && !isMobileScreen && !(0, react_native_device_info_1.isTablet)();
    }, [isMobileScreen]);
    const isWebDevice = (0, react_1.useMemo)(() => { return react_native_2.Platform.OS === 'web'; }, []);
    const isTabDevice = (0, react_1.useMemo)(() => {
        return (0, react_native_device_info_1.isTablet)();
    }, []);
    const isPortrait = (0, react_1.useMemo)(() => {
        return orientation === "PORTRAIT";
    }, [orientation]);
    // const isTabOrDesktopDevice = useMemo(() => {
    //   return isDesktopDevice || isTabDevice || isTabScreen;
    // }, []);
    const isTabOrDesktopDevice = (0, react_1.useMemo)(() => {
        return !isMobileScreen || isTabDevice || isTabScreen;
    }, []);
    const windowHeight = (0, react_native_1.useWindowDimensions)().height;
    const windowWidth = (0, react_native_1.useWindowDimensions)().width;
    // console.log("windowWidth:", windowWidth);
    const deviceWidth = (0, react_1.useMemo)(() => {
        return windowWidth;
    }, [windowWidth]);
    const deviceHeight = (0, react_1.useMemo)(() => {
        return windowHeight;
    }, [windowHeight]);
    const mainWidth = (0, react_1.useMemo)(() => (isMobileScreen ? deviceWidth : deviceWidth - constants_1.DISCOVER_SIDE_TAB_WIDTH), [deviceWidth, isMobileScreen]);
    const usableWidth = (0, react_1.useMemo)(() => {
        return isMobileScreen
            ? deviceWidth - 40
            : deviceWidth - constants_1.DISCOVER_SIDE_TAB_WIDTH;
    }, [isMobileScreen, deviceWidth]);
    return {
        isMobileScreen,
        isDesktopDevice,
        isCompletelyDesktopDevice,
        isTabOrDesktopDevice,
        isTabDevice,
        isTabScreen,
        deviceHeight,
        deviceWidth,
        orientation,
        isPortrait,
        isWebDevice,
        mainWidth,
        usableWidth,
        isSmallScreen,
    };
};
exports.useDevice = useDevice;
