"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColorTheme = exports.Colors = void 0;
const react_1 = require("react");
const useDarkTheme_1 = require("../hooks/useDarkTheme");
exports.Colors = {
    white: "#F4FEFF",
    red: "#EA6769",
    WARNING: "#F61B42",
    WHITE_SECONDARY: "#FFFFFF",
    WHITE_BG_WEB_TAB: "#ffffff08",
    TRANSPARENT_BUTTON_BG: "#ffffff20",
    BORDER_WEB_TAB: "#6558FF30",
    GRADIENT: ["#1CD4F1", "#0061A7"],
    GRADIENT_HOVER: ["#9DEDF8", "#0061A7"],
    GRADIENT_WARNING: ["#9932EB", "#594CF2"],
    GRADIENT_CARD: ["#9932EB", "#594CF2"],
    GRADIENT_PINK: ["#EC008C", "#FC6767"],
    TEXT: "#18191F",
    LIGHT_GRAY: "#6E81A1",
    PRIMARY_GRAY: "#B9B9B9",
    SECONDARY: "#5E51EC",
    PRIMARY_BLUE: "#1CD4F1",
    SECONDARY_BLUE: "#5548EB",
    DARK_GRAY: "#353D49",
    LIGHT_GRAY_PRIMARY: "#8E87EE",
    LIGHT_GRAY_SECONDARY: "#8798B4",
    DARK_THEME_PLACEHOLDER: "#656569",
    BLACK_PRIMARY: "#0F1019",
    PLACEHOLDER_LIGHT_GRAY: "#C0C8D6",
    BORDER_COLOR: "#A0A0A3",
    MESSEGE_BG: "#1CD4F1",
    INPUT_ACTIVE: "#0F1C1E",
    PRIMARY_700: "#0F1C1E",
    DARK_100: "#A0A0A3",
    DARK_200: "#7D7D80",
    DARK_300: "#656569",
    DARK_400: "#535359",
    DARK_500: "#414146",
    DARK_600: "#242427",
    DARK_700: "#121218",
    DARK_1: "#161424",
    PRIMARY_100: "#7EBEC6",
    PRIMARY_300: "#7BE1F1",
    PRIMARY_200: "#6EA6AE",
    INPUT_EMPTY: "#2A2A2F",
    PLACEHOLDER_DARK_COLOR: "#A0A0A3",
    DARK_TEXT: "#A0A0A3",
    GRADIENT_DEFAULT: "#B55CFD",
    GRADIENT_PRESSED: "#5548F3",
    SUCCESS: "#0AEF9D",
    ORANGE: "#FE9738",
    APP_LABEL: "#ffffff66",
    APP_TUTOR: "#7C7878",
    INACTIVECHAT: "#1C1C25",
    TEXT_LABEL: "#6F6F70",
    FREE_PLAN: "#36363D",
    ERROR: "#D43D66",
    PRIMARY_LIGHT: "#2F2C4F",
    DEACTIVATE_SCREEN: "#6F44F0",
    FAILURE: "#F61B42",
    PLANTEXT: "#C058FF",
    PLAN: "#FF8FC5",
    CHAT_HIGHLIGHTS: "#332C80",
    TAB_BG: '#383468',
    NEUTRAL_4A: "#4A4A4A",
};
/**
*
* @returns
*/
const useColorTheme = () => {
    const { getDarkTheme } = (0, useDarkTheme_1.useDarkTheme)();
    const [colorTheme, setMode] = (0, react_1.useState)("");
    const getMode = async () => {
        const colorMode = await getDarkTheme();
        setMode(colorMode);
    };
    (0, react_1.useEffect)(() => {
        getMode();
    });
    return {
        TEXT: colorTheme === "dark" ? exports.Colors.white : exports.Colors.TEXT,
        BG: colorTheme === "dark" ? "#101010" : exports.Colors.white,
        CATEGORY_DARK_BG: colorTheme === "dark" ? exports.Colors.DARK_700 : exports.Colors.white,
        DARK_700: colorTheme === "dark" ? "#121218" : exports.Colors.white,
        PRIMARY_700: colorTheme === "dark" ? exports.Colors.PRIMARY_700 : exports.Colors.white,
        PRIMARY_BG: colorTheme === "dark" ? "#343434" : exports.Colors.WHITE_SECONDARY,
        APP_BG: colorTheme === "dark" ? exports.Colors.DARK_700 : "transparent",
        DARK_100: colorTheme === "dark" ? "#A0A0A3" : "transparent",
        PRIMARY_300: colorTheme === "dark" ? "#6558FF" : "#6558FF",
        DARK_400: colorTheme === "dark" ? "#535359" : "transparent",
        DARK_500: colorTheme === "dark" ? "#414146" : "#414146",
        DARK_600: colorTheme === "dark" ? "#2A2A2F" : "#2A2A2F",
        DARK_TEXT: colorTheme === "dark" ? "#A0A0A3" : "#A0A0A3",
        INPUT_ACTIVE: exports.Colors.PRIMARY_700,
        INPUT_EMPTY: "#2A2A2F",
        OVERLAY_BG: "#2A2A2F",
    };
};
exports.useColorTheme = useColorTheme;
