"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const native_base_1 = require("native-base");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const colors_1 = require("../../common/colors");
const AppInput_1 = require("../../component/AppInput");
/**
 *
 * @param param0
 * @returns
 */
const SearchInput = ({ children, ...rest }) => {
    const [searchText, setSearchText] = (0, react_1.useState)('');
    const defaultStyle = (0, react_1.useMemo)(() => {
        return {
            backgroundColor: colors_1.Colors.INPUT_EMPTY,
            color: colors_1.Colors.DARK_200,
        };
    }, []);
    // Style for rightElement & Left element stack
    const defaultStackStyle = (0, react_1.useMemo)(() => {
        return {
            bg: colors_1.Colors.INPUT_EMPTY,
        };
    }, []);
    const defaultFocusStyle = (0, react_1.useMemo)(() => {
        return {
            style: { color: colors_1.Colors.white, backgroundColor: colors_1.Colors.INPUT_ACTIVE },
            selectionColor: colors_1.Colors.white,
            placeholderTextColor: colors_1.Colors.white,
            _stack: {
                background: colors_1.Colors.INPUT_ACTIVE,
            },
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)(AppInput_1.AppInput, { allowFontScaling: false, variant: "rounded", placeholderTextColor: colors_1.Colors.DARK_300, borderRadius: 8, borderWidth: 1, style: defaultStyle, _focus: { ...defaultFocusStyle, ...(rest?._focus || {}) }, _stack: defaultStackStyle, _hover: {
            borderColor: colors_1.Colors.PRIMARY_100,
        }, borderColor: colors_1.Colors.DARK_500, focusOutlineColor: colors_1.Colors.DARK_500, 
        // Do not auto focus if text is already provided. Feedback 13 jun
        autoFocus: false, InputRightElement: searchText.length > 0 ? ((0, jsx_runtime_1.jsx)(native_base_1.IconButton, { onPress: () => { }, size: 4, marginRight: rest.paddingRight || rest.pr || rest.p, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faClose, size: 16, color: colors_1.Colors.DARK_300 }) })) : ((0, jsx_runtime_1.jsx)(native_base_1.IconButton, { onPress: () => { }, size: 4, marginRight: rest.paddingRight || rest.pr || rest.p, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faSearch, size: 16, color: colors_1.Colors.DARK_300 }) })), onChangeText: (text) => setSearchText(text), ...rest, children: children }));
};
exports.default = SearchInput;
