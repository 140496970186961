"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAutoSentMessage = exports.selectGenerationImageId = exports.selectFile = exports.selectIsChatImageLoading = exports.selectIsCharacterTyping = exports.selectLastConversation = exports.selectLastMessage = exports.selectStoredMessages = exports.selectConversation = exports.selectCharacterProfile = exports.selectUsername = exports.selectTempUserId = exports.selectDisplayName = exports.selectAccessToken = exports.selectfbUserId = exports.selectUserType = exports.selectUserEmail = exports.selectUserId = exports.selectUser = exports.selectFbUser = exports.selectIsUserLogged = void 0;
const selectIsUserLogged = (state) => Boolean(state.getUserOptions.fbUser);
exports.selectIsUserLogged = selectIsUserLogged;
const selectFbUser = (state) => state.getUserOptions.fbUser;
exports.selectFbUser = selectFbUser;
const selectUser = (state) => state.getUserOptions.appUser;
exports.selectUser = selectUser;
const selectUserId = (state) => state.getUserOptions.appUser?.id;
exports.selectUserId = selectUserId;
const selectUserEmail = (state) => state.getUserOptions.fbUser?.email;
exports.selectUserEmail = selectUserEmail;
const selectUserType = (state) => state.getUserOptions.appUser?.type;
exports.selectUserType = selectUserType;
const selectfbUserId = (state) => state.getUserOptions.fbUser?.uid;
exports.selectfbUserId = selectfbUserId;
const selectAccessToken = (state) => state.getUserOptions.authToken;
exports.selectAccessToken = selectAccessToken;
const selectDisplayName = (state) => state.getUserOptions.fbUser?.displayName;
exports.selectDisplayName = selectDisplayName;
const selectTempUserId = (state) => state.getUserOptions.tempUserId;
exports.selectTempUserId = selectTempUserId;
const selectUsername = (state) => state.getUserOptions.appUser?.username;
exports.selectUsername = selectUsername;
const selectCharacterProfile = (state) => state.getUserOptions.characterProfile;
exports.selectCharacterProfile = selectCharacterProfile;
const selectConversation = (state) => state.getUserOptions.conversation;
exports.selectConversation = selectConversation;
const selectStoredMessages = (state) => state.getUserOptions.storedMessages;
exports.selectStoredMessages = selectStoredMessages;
const selectLastMessage = (state) => state.getUserOptions.lastMessage;
exports.selectLastMessage = selectLastMessage;
const selectLastConversation = (state) => state.getUserOptions.lastConversation;
exports.selectLastConversation = selectLastConversation;
const selectIsCharacterTyping = (state) => state.getUserOptions.IsCharacterTyping;
exports.selectIsCharacterTyping = selectIsCharacterTyping;
const selectIsChatImageLoading = (state) => state.getUserOptions.isImageLoading;
exports.selectIsChatImageLoading = selectIsChatImageLoading;
const selectFile = (state) => state.getUserOptions.file;
exports.selectFile = selectFile;
const selectGenerationImageId = (state) => state.getUserOptions.generatedImageId;
exports.selectGenerationImageId = selectGenerationImageId;
const selectAutoSentMessage = (state) => state.getUserOptions.autoSentMessage;
exports.selectAutoSentMessage = selectAutoSentMessage;
