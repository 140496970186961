"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_track_player_1 = __importStar(require("react-native-track-player"));
const react_native_reanimated_1 = __importStar(require("react-native-reanimated"));
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
const AppText_1 = require("../AppText");
const document_1 = require("../../services/document");
const utils_1 = require("../../helper/utils");
const spacing_1 = require("../../common/spacing");
const events = [react_native_track_player_1.Event.PlaybackState];
function ReadDocument({ source, textSize = 40 }) {
    const { position, duration } = (0, react_native_track_player_1.useProgress)();
    const [hasReadStarted, setHasReadStarted] = (0, react_1.useState)(false);
    const [isPlaying, setIsPlaying] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const loadingSpinnerRotation = (0, react_native_reanimated_1.useSharedValue)(0);
    const animatedLoadingStyles = (0, react_native_reanimated_1.useAnimatedStyle)(() => ({
        transform: [{ rotateZ: `${loadingSpinnerRotation.value}deg` }],
    }), [loadingSpinnerRotation]);
    (0, react_native_track_player_1.useTrackPlayerEvents)(events, (event) => {
        if (event.type === react_native_track_player_1.Event.PlaybackState) {
            switch (event.state) {
                case "playing":
                    setIsPlaying(true);
                    break;
                case "stopped":
                case "paused":
                    setIsPlaying(false);
                    break;
                case "ended":
                    setIsPlaying(false);
                    break;
                case "error":
                    setIsPlaying(false);
            }
        }
    });
    const initSpeech = (0, react_1.useCallback)(async (abortController) => {
        setIsLoading(true);
        setHasReadStarted(true);
        !abortController?.signal.aborted && await react_native_track_player_1.default.reset();
        if (typeof source === 'string') {
            try {
                !abortController?.signal.aborted && await react_native_track_player_1.default.add({
                    url: source,
                    title: "Track Title",
                    artist: "Track Artist",
                });
                !abortController?.signal.aborted && await react_native_track_player_1.default.play();
            }
            catch {
            }
            setIsLoading(false);
        }
        else {
            (0, document_1.generateFiveMinutesAudio)(source, () => { }).then(() => {
                setIsLoading(false);
            });
        }
    }, [source]);
    (0, react_1.useEffect)(() => {
        if (isLoading) {
            loadingSpinnerRotation.value = (0, react_native_reanimated_1.withRepeat)((0, react_native_reanimated_1.withTiming)(360, {
                duration: 2000,
                easing: react_native_reanimated_1.Easing.linear,
            }), 200);
            return () => {
                (0, react_native_reanimated_1.cancelAnimation)(loadingSpinnerRotation);
                loadingSpinnerRotation.value = 0;
            };
        }
    }, [isLoading]);
    (0, react_1.useEffect)(() => {
        const abortController = new AbortController();
        initSpeech(abortController);
        return () => {
            react_native_track_player_1.default.reset();
            abortController.abort();
        };
    }, [initSpeech]);
    const handleControlPress = (0, react_1.useCallback)(() => {
        switch (true) {
            case !hasReadStarted:
                initSpeech();
                break;
            case isPlaying:
                react_native_track_player_1.default.pause();
                break;
            default:
                react_native_track_player_1.default.play();
        }
    }, [hasReadStarted, isPlaying, initSpeech]);
    const handleRewind = (0, react_1.useCallback)(() => {
        if (position - 10 < 0) {
            react_native_track_player_1.default.seekTo(0);
        }
        else {
            react_native_track_player_1.default.seekTo(position - 10);
        }
    }, [position]);
    const handleForward = (0, react_1.useCallback)(() => {
        const totalDuration = Math.floor(duration);
        if (position + 10 > totalDuration) {
            react_native_track_player_1.default.seekTo(totalDuration);
        }
        else {
            react_native_track_player_1.default.seekTo(position + 10);
        }
    }, [position, duration]);
    return ((0, jsx_runtime_1.jsxs)(react_native_reanimated_1.default.View, { style: styles.root, entering: react_native_reanimated_1.FadeInUp, exiting: react_native_reanimated_1.FadeOutUp, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { children: (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { activeOpacity: 0.7, onPress: handleControlPress, style: styles.iconContainer, children: (0, jsx_runtime_1.jsx)(react_native_reanimated_1.default.View, { style: animatedLoadingStyles, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: isLoading && pro_solid_svg_icons_1.faSpinner
                                || (isPlaying ? pro_solid_svg_icons_1.faPause : pro_solid_svg_icons_1.faPlay), size: 16, color: "white", style: animatedLoadingStyles }) }) }) }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: "white", fontSize: textSize, fontWeight: "600", lineHeight: 48, flex: 1, children: (0, utils_1.formatTime)(position) }), (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { activeOpacity: 0.7, onPress: handleRewind, style: styles.iconContainer, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_regular_svg_icons_1.faArrowRotateLeft, size: 20, color: 'white' }) }), (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { activeOpacity: 0.7, style: styles.iconContainer, onPress: handleForward, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_regular_svg_icons_1.faArrowRotateRight, size: 20, color: "white" }) })] }));
}
exports.default = ReadDocument;
;
const styles = react_native_1.StyleSheet.create({
    root: {
        backgroundColor: '#1B1B22',
        gap: spacing_1.Spacing.SPACING_3XL,
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: spacing_1.Spacing.SPACING_XL,
        paddingVertical: 14,
        borderRadius: 12,
    },
    iconContainer: {
        backgroundColor: '#A0A0A329',
        padding: 8,
        borderRadius: 16,
    },
});
