/**
 * @format
 */

import { AppRegistry } from "react-native";
import App from "./src/App";

// Inter fonts
import InterBold from './src/assets/fonts/Inter/Inter-Bold.woff2';
import InterLight from './src/assets/fonts/Inter/Inter-Light.woff2';
import InterMedium from './src/assets/fonts/Inter/Inter-Medium.woff2';
import Inter from './src/assets/fonts/Inter/Inter-Regular.woff2';

// Geist fonts
import GeistBold from './src/assets/fonts/Geist/Geist-Bold.woff2';
import GeistLight from './src/assets/fonts/Geist/Geist-Light.woff2';
import GeistMedium from './src/assets/fonts/Geist/Geist-Medium.woff2';
import GeistRegular from './src/assets/fonts/Geist/Geist-Regular.woff2';

// Noto Serif fonts
import NotoSerifBold from './src/assets/fonts/Noto Serif/NotoSerif-Bold.woff2';
import NotoSerifRegular from './src/assets/fonts/Noto Serif/NotoSerif-Regular.woff2';

// OpenDyslexic fonts
import OpenDyslexicBold from './src/assets/fonts/OpenDyslexic/OpenDyslexic-Bold.woff';
import OpenDyslexicRegular from './src/assets/fonts/OpenDyslexic/OpenDyslexic-Regular.woff';

// Inter styles
const InterFontStyles = `@font-face {
  src: url(${Inter});
  font-family: 'Inter';
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}`;

const InterLightFontStyles = `@font-face {
  src: url(${InterLight});
  font-family: 'Inter-Light';
}`;

const InterMediumFontStyles = `@font-face {
  src: url(${InterMedium});
  font-family: 'Inter';
  font-weight: 500;
}`;

const InterBoldFontStyles = `@font-face {
  src: url(${InterBold});
  font-family: 'Inter-Bold';
}`;

// Geist styles
const GeistFontStyles = `@font-face {
  src: url(${GeistRegular});
  font-family: 'Geist';
  font-weight: normal;
}`;

const GeistLightFontStyles = `@font-face {
  src: url(${GeistLight});
  font-family: 'Geist-Light';
}`;

const GeistMediumFontStyles = `@font-face {
  src: url(${GeistMedium});
  font-family: 'Geist-Medium';
}`;

const GeistBoldFontStyles = `@font-face {
  src: url(${GeistBold});
  font-family: 'Geist-Bold';
}`;

// Noto Serif styles
const NotoSerifFontStyles = `@font-face {
  src: url(${NotoSerifRegular});
  font-family: 'NotoSerif';
  font-weight: normal;
}`;

const NotoSerifBoldFontStyles = `@font-face {
  src: url(${NotoSerifBold});
  font-family: 'NotoSerif-Bold';
}`;

// OpenDyslexic styles
const OpenDyslexicFontStyles = `@font-face {
  src: url(${OpenDyslexicRegular});
  font-family: 'OpenDyslexic';
  font-weight: normal;
}`;

const OpenDyslexicBoldFontStyles = `@font-face {
  src: url(${OpenDyslexicBold});
  font-family: 'OpenDyslexic-Bold';
}`;

const style = document.createElement('style');
// Add Inter
style.appendChild(document.createTextNode(InterFontStyles));
style.appendChild(document.createTextNode(InterLightFontStyles));
style.appendChild(document.createTextNode(InterMediumFontStyles));
style.appendChild(document.createTextNode(InterBoldFontStyles));
// Add Geist Sans
style.appendChild(document.createTextNode(GeistFontStyles));
style.appendChild(document.createTextNode(GeistLightFontStyles));
style.appendChild(document.createTextNode(GeistMediumFontStyles));
style.appendChild(document.createTextNode(GeistBoldFontStyles));
// Add Noto Serif
style.appendChild(document.createTextNode(NotoSerifFontStyles));
style.appendChild(document.createTextNode(NotoSerifBoldFontStyles));
// Add OpenDyslexic
style.appendChild(document.createTextNode(OpenDyslexicFontStyles));
style.appendChild(document.createTextNode(OpenDyslexicBoldFontStyles));
document.head.appendChild(style);

AppRegistry.registerComponent('talktoo', () => App);
AppRegistry.runApplication('talktoo', {
  rootTag: document.getElementById('root'),
});