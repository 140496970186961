"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deviceContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
exports.deviceContext = (0, react_1.createContext)({
    isKeyboardOpened: false,
});
const Provider = exports.deviceContext.Provider;
;
function DeviceProvider({ children }) {
    const [isKeyboardOpened, setIsKeyboardOpened] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const keyboardArisesObserver = () => {
            setIsKeyboardOpened(window.innerHeight !== Math.round(visualViewport?.height ?? window.innerHeight));
        };
        visualViewport?.addEventListener('resize', keyboardArisesObserver);
        return () => visualViewport?.removeEventListener('resize', keyboardArisesObserver);
    }, []);
    return ((0, jsx_runtime_1.jsx)(Provider, { value: {
            isKeyboardOpened
        }, children: children }));
}
exports.default = DeviceProvider;
;
