"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const native_base_1 = require("native-base");
const GradientButton_1 = require("../../component/GradientButton");
const AppText_1 = require("../AppText");
const AppIcons_1 = require("../AppIcons");
const colors_1 = require("../../common/colors");
const useDevice_1 = require("../../hooks/useDevice");
const react_native_1 = require("react-native");
const react_redux_1 = require("react-redux");
const rootNavigation_1 = require("../../navigation/rootNavigation");
function UpgradeBanner() {
    const fbUser = (0, react_redux_1.useSelector)((state) => state.getUserOptions.fbUser);
    const isUserLogged = (0, react_1.useMemo)(() => (Boolean(fbUser)), [fbUser]);
    const handleMembershipAction = (0, react_1.useCallback)(async () => {
        if (isUserLogged) {
            react_native_1.Linking.openURL('mailto:info@makr.ai?subject=How\'s%20your%20experience%20so%20far?');
        }
        else {
            (0, rootNavigation_1.navigate)('LoginPage');
        }
    }, [isUserLogged]);
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsxs)(native_base_1.VStack, { style: {
            bottom: 0,
            backgroundColor: colors_1.Colors.DARK_600,
            borderTopRightRadius: 24,
            borderTopLeftRadius: 24,
            height: isMobileScreen ? 220 : 240,
            zIndex: 1,
            paddingTop: isMobileScreen ? 15 : 20,
            paddingHorizontal: isMobileScreen ? 20 : 10,
            alignItems: "center",
            width: "100%",
        }, space: 4, children: [(0, jsx_runtime_1.jsx)(AppIcons_1.SummaraizerLogo, {}), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.DARK_100, fontSize: isMobileScreen ? 16 : 14, textAlign: "center", children: "Thanks for exploring Summarizer in its public alpha! We hope you enjoyed your experience. You've reached today's limit, but we'd love to see you back tomorrow for more!" }), (0, jsx_runtime_1.jsx)(GradientButton_1.GradientButton, { text: "Contact Us", fullWidth: false, onPress: handleMembershipAction })] }));
}
exports.default = UpgradeBanner;
