"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_1 = require("react");
const colors_1 = require("../../common/colors");
const react_redux_1 = require("react-redux");
const rootNavigation_1 = require("../../navigation/rootNavigation");
const useAnalytics_1 = require("../../hooks/useAnalytics");
const useDevice_1 = require("../../hooks/useDevice");
const user_1 = require("../../slices/user");
const AppText_1 = require("../AppText");
/**
 *
 * @param props
 * @returns
 */
const MemberShipFreePlan = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { logEvent } = (0, useAnalytics_1.useAnalytics)();
    const subscription = (0, react_redux_1.useSelector)((state) => state.subscriptionPlan?.subscription);
    const messageQuota = (0, react_redux_1.useSelector)((state) => state.subscriptionPlan.quota?.conversations);
    const characterQuota = (0, react_redux_1.useSelector)((state) => state.subscriptionPlan.quota?.characters);
    const fbUser = (0, react_redux_1.useSelector)((state) => state.getUserOptions.fbUser);
    const handleMemberShip = async () => {
        await logEvent("upgrade_view", { uid: fbUser?.uid });
        if (isWebDevice) {
            (0, rootNavigation_1.navigate)("Pricing");
        }
        else {
            dispatch((0, user_1.setUserAction)("membership"));
        }
    };
    const { isTabOrDesktopDevice, isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    const messageCount = (0, react_1.useMemo)(() => {
        if (messageQuota?.quota) {
            return Math.max(messageQuota?.quota - messageQuota.consumed, 0);
        }
        return 0;
    }, [messageQuota]);
    const characterCount = (0, react_1.useMemo)(() => {
        if (characterQuota?.quota) {
            return Math.max(characterQuota?.quota - characterQuota.consumed, 0);
        }
        return 0;
    }, [characterQuota]);
    return ((0, jsx_runtime_1.jsx)(native_base_1.VStack, { space: "5", my: isTabOrDesktopDevice ? 0 : isWebDevice ? undefined : 2, children: (0, jsx_runtime_1.jsx)(native_base_1.VStack, { space: isWebDevice && isMobileScreen ? 3 : 4, rounded: isWebDevice && isMobileScreen ? 12 : 15, bg: isWebDevice && isMobileScreen ? colors_1.Colors.DARK_600 : colors_1.Colors.DARK_600, px: isWebDevice && isMobileScreen ? undefined : 3.5, p: isWebDevice && isMobileScreen ? 3 : undefined, pb: 3, marginBottom: isWebDevice && isMobileScreen ? 6 : 8, children: (0, jsx_runtime_1.jsx)(native_base_1.VStack, { space: isWebDevice && isMobileScreen ? 3 : undefined, children: (0, jsx_runtime_1.jsxs)(native_base_1.VStack, { children: [(0, jsx_runtime_1.jsx)(native_base_1.HStack, { justifyContent: "space-between", children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { fontSize: 18, fontWeight: "400", lineHeight: isWebDevice && isMobileScreen ? 25.2 : undefined, color: isWebDevice && isMobileScreen ? colors_1.Colors.DARK_200 : colors_1.Colors.DARK_200, mt: isWebDevice && isMobileScreen ? undefined : 2, children: !subscription?.identifier
                                    ? "Free Plan"
                                    : `You are on Premium Plan` }) }), (0, jsx_runtime_1.jsxs)(AppText_1.AppText, { color: isWebDevice && isMobileScreen ? "white" : colors_1.Colors.white, fontSize: isWebDevice && isMobileScreen ? 16 : undefined, lineHeight: isWebDevice && isMobileScreen ? 22.4 : undefined, mt: isWebDevice && isMobileScreen ? undefined : 2, children: [messageCount, " messages left for today"] }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: isWebDevice && isMobileScreen ? "white" : colors_1.Colors.white, fontSize: isWebDevice && isMobileScreen ? 16 : undefined, lineHeight: isWebDevice && isMobileScreen ? 22.4 : undefined, mt: isWebDevice && isMobileScreen ? undefined : 2, children: characterCount === 1 ? `${characterCount} summary left` : `${characterCount} summaries left` })] }) }) }) }));
};
exports.default = (0, react_1.memo)(MemberShipFreePlan);
