"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppHelpText = exports.AppSubHeadingBold = exports.AppSubHeading = exports.DiscoverScreenHeading = exports.DiscoverAppHeading = exports.AppHeading = exports.AppText = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_redux_1 = require("react-redux");
const constants_1 = require("../../common/constants");
const useDevice_1 = require("../../hooks/useDevice");
const colors_1 = require("../../common/colors");
const theme_1 = require("../../helper/theme");
const AppText = (props) => {
    const { selectedFontId, selectedVariant } = (0, react_redux_1.useSelector)((state) => state.fontSettings);
    const selectedFont = constants_1.AVAILABLE_FONTS.find(font => font.id === selectedFontId);
    const fontFamily = selectedFont?.variants?.[selectedVariant] || selectedFont?.fontFamily;
    return ((0, jsx_runtime_1.jsx)(native_base_1.Text, { ...props, style: [
            {
                fontFamily,
            },
            props.style,
        ], children: props.children }));
};
exports.AppText = AppText;
const AppHeading = ({ children, ...rest }) => {
    const { isMobileScreen, isWebDevice } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(exports.AppText, { color: colors_1.Colors.white, fontWeight: 700, fontSize: isMobileScreen && isWebDevice ? 24 : (0, theme_1.normalize)(28), lineHeight: isMobileScreen && isWebDevice ? 32 : undefined, textAlign: "center", alignSelf: "center", allowFontScaling: false, ...rest, children: children }));
};
exports.AppHeading = AppHeading;
const DiscoverAppHeading = ({ children, ...rest }) => {
    const { isMobileScreen, isWebDevice } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(exports.AppText, { color: colors_1.Colors.WHITE_SECONDARY, fontWeight: 700, fontSize: isWebDevice && isMobileScreen ? 24 : isWebDevice ? 20 : (0, theme_1.scaleFont)(20), lineHeight: 32, textAlign: "center", alignSelf: "center", allowFontScaling: false, ...rest, children: children }));
};
exports.DiscoverAppHeading = DiscoverAppHeading;
const DiscoverScreenHeading = ({ children, ...rest }) => {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(exports.AppText, { color: colors_1.Colors.WHITE_SECONDARY, fontWeight: 700, fontSize: isWebDevice && !isMobileScreen ? 40 : (0, theme_1.scaleFont)(24), textAlign: "center", alignSelf: "center", allowFontScaling: false, ...rest, children: children }));
};
exports.DiscoverScreenHeading = DiscoverScreenHeading;
const AppSubHeading = ({ children, ...rest }) => {
    return ((0, jsx_runtime_1.jsx)(exports.AppText, { color: colors_1.Colors.white, fontWeight: 700, fontSize: 20, textAlign: "center", alignSelf: "center", allowFontScaling: false, ...rest, children: children }));
};
exports.AppSubHeading = AppSubHeading;
const AppSubHeadingBold = ({ children, ...rest }) => {
    return ((0, jsx_runtime_1.jsx)(exports.AppSubHeading, { fontWeight: 700, ...rest, children: children }));
};
exports.AppSubHeadingBold = AppSubHeadingBold;
const AppHelpText = ({ children, ...rest }) => {
    return ((0, jsx_runtime_1.jsx)(exports.AppText, { color: colors_1.Colors.white, fontSize: 14, textAlign: "center", alignSelf: "center", allowFontScaling: false, ...rest, children: children }));
};
exports.AppHelpText = AppHelpText;
