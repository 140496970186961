"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const useAppMessage_1 = require("../../../hooks/useAppMessage");
function UploadControl({ controlId, fileType, invalidTypeMessage, fileAction, children, }) {
    const { showNotification } = (0, useAppMessage_1.useAppMessage)();
    const handleFileInput = (0, react_1.useCallback)(({ target: { files } }) => {
        const upLoadedFile = files[0];
        if (!fileType
            || typeof fileType === 'string'
            ? fileType === upLoadedFile.type
            : fileType.some((allowedType) => allowedType === upLoadedFile.type)) {
            fileAction(upLoadedFile);
        }
        else {
            showNotification('Error', invalidTypeMessage ?? '', 'error');
        }
        ;
    }, [fileAction]);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: controlId, children: children }), (0, jsx_runtime_1.jsx)("input", { id: controlId, type: 'file', accept: typeof fileType === 'string' ? fileType : fileType?.join(', '), value: '', onChange: handleFileInput, style: styles.fileControl })] }));
}
exports.default = UploadControl;
;
const styles = react_native_1.StyleSheet.create({
    fileControl: {
        display: 'none',
    },
});
