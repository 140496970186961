"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const useDevice_1 = require("../../hooks/useDevice");
const colors_1 = require("../../common/colors");
const AppInput_1 = require("../../component/AppInput");
const react_redux_1 = require("react-redux");
const constants_1 = require("../../common/constants");
const MesssageBoxInput = ({ children, editable, audioMode, ...rest }) => {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    const selectedFontId = (0, react_redux_1.useSelector)((state) => state.fontSettings.selectedFontId);
    const selectedFont = constants_1.AVAILABLE_FONTS.find(font => font.id === selectedFontId);
    const fontFamily = selectedFont?.fontFamily || 'system font';
    const defaultStyle = (0, react_1.useMemo)(() => {
        return {
            backgroundColor: colors_1.Colors.DARK_700,
            color: rest?.defaultValue || audioMode
                ? colors_1.Colors.WHITE_SECONDARY
                : isWebDevice && isMobileScreen
                    ? colors_1.Colors.DARK_300
                    : colors_1.Colors.DARK_200,
            fontSize: isWebDevice && isMobileScreen ? 16 : undefined,
            lineHeight: isWebDevice && isMobileScreen ? 20 : undefined,
            minHeight: 4,
            textAlignVertical: "top",
            fontFamily: fontFamily,
        };
    }, [rest?.defaultValue, audioMode, fontFamily]);
    // Style for rightElement & Left element stack
    const defaultStackStyle = (0, react_1.useMemo)(() => {
        return {
            bg: colors_1.Colors.DARK_700,
        };
    }, []);
    const defaultFocusStyle = (0, react_1.useMemo)(() => {
        return {
            style: { color: colors_1.Colors.white, backgroundColor: colors_1.Colors.DARK_700 },
            selectionColor: colors_1.Colors.white,
            placeholderTextColor: colors_1.Colors.white,
            _stack: {
                background: colors_1.Colors.DARK_700,
            },
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)(AppInput_1.AppInput, { allowFontScaling: false, style: defaultStyle, _focus: defaultFocusStyle, _stack: defaultStackStyle, borderColor: colors_1.Colors.DARK_500, focusOutlineColor: colors_1.Colors.DARK_500, _input: {
            selectionColor: colors_1.Colors.white,
            cursorColor: colors_1.Colors.white,
            borderColor: colors_1.Colors.PLACEHOLDER_DARK_COLOR,
        }, ref: rest?.inputRef, ...rest, children: children }));
};
exports.default = (0, react_1.memo)(MesssageBoxInput);
