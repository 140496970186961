"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const store_1 = require("../store");
const rootNavigation_1 = require("../navigation/rootNavigation");
const thunks_1 = require("../store/thunks");
const document_1 = require("../slices/document");
const appState_1 = require("../slices/appState");
const application_host_1 = require("../common/application-host");
const error_1 = require("../common/error");
const constants_1 = require("../common/constants");
function useRequest() {
    const dispatch = (0, store_1.useAppDispatch)();
    const ApiErrorHandler = (0, react_1.useCallback)((error) => {
        switch (error.reason) {
            case 'Not Found':
                dispatch((0, appState_1.setAppError)(constants_1.NOT_FOUND_MESSAGE));
                break;
            case 'Out of Quota':
                try {
                    const errorMessages = JSON.parse(error.message);
                    const errorText = errorMessages.find((message) => message.product === application_host_1.APP_PRODUCT)?.text;
                    dispatch((0, appState_1.setAppError)(errorText ?? error.message));
                }
                catch {
                    dispatch((0, appState_1.setAppError)(error.message));
                }
                break;
            default:
                dispatch((0, appState_1.setAppError)(error.message));
        }
        switch (error.module) {
            case 'Summary':
                (0, rootNavigation_1.navigate)('Home', { screen: 'Documents' });
                dispatch((0, document_1.setDocumentUploadComplete)(true));
                break;
        }
        ;
        return null;
    }, [dispatch]);
    const RequestErrorHandler = (0, react_1.useCallback)((error) => {
        switch (error.reason) {
            case 'Session Out':
                dispatch((0, appState_1.setAppError)(constants_1.SESSION_OUT_MESSAGE));
                dispatch((0, thunks_1.logOut)());
                break;
            default:
                dispatch((0, appState_1.setAppError)(constants_1.DEFAULT_ERROR_MESSAGE));
                console.error(error);
        }
        switch (error.module) {
            case 'Summary':
                (0, rootNavigation_1.navigate)('Home', { screen: 'Documents' });
                break;
        }
        ;
        return null;
    }, []);
    const customErrorActionHandler = (0, react_1.useCallback)((error, handledErrorType, customErrorActions, defaultAction) => {
        const customErrorAction = customErrorActions[handledErrorType.name];
        if (customErrorAction) {
            customErrorAction(error);
        }
        else {
            defaultAction(error);
        }
        ;
        return null;
    }, []);
    const customRequest = (0, react_1.useCallback)((request, customErrorActions, ...params) => request(...Array.from(params))
        .then((result) => {
        switch (true) {
            case result instanceof error_1.APIError:
                return customErrorActionHandler(result, error_1.APIError, customErrorActions, ApiErrorHandler);
            case result instanceof error_1.RequestError:
                return customErrorActionHandler(result, error_1.RequestError, customErrorActions, RequestErrorHandler);
            default:
                return result;
        }
    }), [
        customErrorActionHandler,
        ApiErrorHandler,
        RequestErrorHandler,
    ]);
    const request = (0, react_1.useCallback)((request, ...params) => request(...Array.from(params))
        .then((result) => {
        switch (true) {
            case result instanceof error_1.APIError:
                return ApiErrorHandler(result);
            case result instanceof error_1.RequestError:
                return RequestErrorHandler(result);
            default:
                return result;
        }
    }), [ApiErrorHandler, RequestErrorHandler]);
    return {
        request,
        customRequest,
    };
}
exports.default = useRequest;
;
