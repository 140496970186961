"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateFiveMinutesAudio = exports.getDocumentImages = exports.uploadImage = exports.regenerateImage = exports.deleteDocument = exports.editDocumentTitle = exports.scrapURL = exports.transcriptYoutubeVideo = exports.followUpSharedDocument = exports.createSummaryFromText = exports.createSummary = exports.updateNote = exports.getFirstNote = exports.getNotes = exports.createNote = exports.getShareLink = exports.getSharedSummary = exports.getSummary = exports.getMyDocuments = exports.getAllDocuments = void 0;
const axios_1 = require("axios");
const streaming_1 = require("./streaming");
const interceptorsCached_1 = require("./interceptorsCached");
const interceptorsAnonAuth_1 = require("./interceptorsAnonAuth");
const document_1 = require("../helper/document");
const application_host_1 = require("../common/application-host");
const apis_1 = require("../common/apis");
const error_1 = require("../common/error");
const EXISTENT_SHARED_DOCUMENT_MESSAGE = 'Shared document already exists!';
const DOCUMENT_PAYLOAD = {
    attitude: "",
    background: "Expert in analysing documents.",
    characterName: "Document Doctor",
    description: "",
    generationId: "",
    language: "",
};
const TEXT_PAYLOAD = {
    attitude: "",
    background: "Expert in analyzing documents.",
    characterName: "Document Doctor",
    description: "",
    generationid: "",
    youtubeUrl: "",
    webUrl: "",
    language: "",
};
function getAllDocuments() {
    return (interceptorsAnonAuth_1.networkCall
        .get(`${apis_1.APP_CONSTANTS.documents.share}?userId=${application_host_1.SUMMARAIZER_ADMIN}&limit=100`).then((response) => (!response.success || response.error
        ? new error_1.APIError(response.error?.message)
        : response.data)).catch((error) => new error_1.RequestError(error)));
}
exports.getAllDocuments = getAllDocuments;
;
function getMyDocuments() {
    return interceptorsCached_1.cachedAuthCall.get(`${apis_1.APP_CONSTANTS.documents.index}?sort=DESC`).then((response) => response.data).catch((error) => {
        if (error.code === 'ERR_CANCELED') {
            return null;
        }
        ;
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized ? 'Session Out' : undefined,
        });
    });
}
exports.getMyDocuments = getMyDocuments;
;
function getSummary(conversationId) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.summaryJson, { conversationId }).then((response) => {
        if (response.success) {
        }
        else {
            switch (response.error?.message) {
                case 'Error while getting the JSON from S3 database. Error: NoSuchKey: The specified key does not exist.':
                    return new error_1.APIError(response.error.message, { reason: 'Not Found', module: 'Summary' });
                default:
                    return new error_1.APIError(response.error?.message, { module: 'Summary' });
            }
        }
        return response.success
            ? response.data
            : new error_1.APIError(response.error?.message, { module: 'Summary' });
    }).catch((error) => new error_1.RequestError(error, { module: 'Summary' }));
}
exports.getSummary = getSummary;
;
function getSharedSummary(shareId, password) {
    return interceptorsCached_1.cachedAuthCall.post(`${apis_1.APP_CONSTANTS.documents.shareDetails}`, { shareId, ...(password && { password }) }).then((response) => (response.success ? response.data : new error_1.APIError(response.error?.message, { module: 'Summary' }))).catch((error) => new error_1.RequestError(error, { module: 'Summary' }));
}
exports.getSharedSummary = getSharedSummary;
;
function getShareLink(conversationId, fileId, password) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.share, {
        conversationId,
        fileId,
        ...(typeof password === 'string' && { password }),
        ...(typeof password === 'boolean' && { resetPassword: password }),
    }).then((response) => (response.success || response.error?.message === 'SharedId already exists'
        ? response.data
        : new error_1.APIError(response.error?.message))).catch((error) => new error_1.RequestError(error));
}
exports.getShareLink = getShareLink;
;
//Notes
function createNote(note, characterId) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.notes, { note, characterId }).then((response) => {
        if (!response.success) {
            return new error_1.APIError(response.error?.message);
        }
        return response.data;
    }).catch((error) => new error_1.RequestError(error));
}
exports.createNote = createNote;
function getNotes(characterId) {
    return interceptorsCached_1.cachedAuthCall.get(`${apis_1.APP_CONSTANTS.documents.notes}?characterId=${characterId}`).then((response) => {
        if (!response.success) {
            return new error_1.APIError(response.error?.message);
        }
        return response.data;
    }).catch((error) => new error_1.RequestError(error));
}
exports.getNotes = getNotes;
function getFirstNote(characterId) {
    return interceptorsCached_1.cachedAuthCall.get(`${apis_1.APP_CONSTANTS.documents.notes}?characterId=${characterId}`).then((response) => {
        if (!response.success) {
            return new error_1.APIError(response.error?.message);
        }
        return response.data.length > 0 ? response.data[0] : null;
    }).catch((error) => new error_1.RequestError(error));
}
exports.getFirstNote = getFirstNote;
function updateNote(noteId, note) {
    return interceptorsCached_1.cachedAuthCall.put(`${apis_1.APP_CONSTANTS.documents.notes}/${noteId}`, { note }).then((response) => {
        if (!response.success) {
            return new error_1.APIError(response.error?.message);
        }
        return response.data;
    }).catch((error) => new error_1.RequestError(error));
}
exports.updateNote = updateNote;
function createSummary(file, language, streamingHandler, requestHandler) {
    const SUMMARY_DETECTION_REGEX = /###\s?Summary/;
    return (0, streaming_1.stream)(apis_1.APP_CONSTANTS.documents.createSummaryGemini, { ...DOCUMENT_PAYLOAD, language }, file, streamingHandler).then((response) => {
        if (typeof response !== 'string') {
            return new error_1.APIError(response.error?.message, { module: 'Summary', reason: 'Out of Quota' });
        }
        ;
        if (!SUMMARY_DETECTION_REGEX.test(response)) {
            return new error_1.APIError(response, { module: 'Summary' });
        }
        requestHandler(response);
    }).catch((error) => {
        if (error instanceof error_1.AuthenticationError) {
            console.error('Authentication Error');
        }
        console.error(error);
    });
}
exports.createSummary = createSummary;
;
function createSummaryFromText(title, text, language, streamingHandler, requestHandler, youtubeUrl, webUrl) {
    const SUMMARY_DETECTION_REGEX = /###\s?Summary/;
    const payload = {
        ...TEXT_PAYLOAD,
        language,
        youtubeUrl: youtubeUrl || "",
        webUrl: webUrl || "",
    };
    return (0, streaming_1.streamFromText)(`${application_host_1.ADD_API}${apis_1.APP_CONSTANTS.documents.createSummaryGemini}`, title, text, payload, streamingHandler).then((response) => {
        if (typeof response !== 'string') {
            return new error_1.APIError(response.error?.message, { module: 'Summary', reason: 'Out of Quota' });
        }
        ;
        if (!SUMMARY_DETECTION_REGEX.test(response)) {
            return new error_1.APIError(response, { module: 'Summary' });
        }
        requestHandler(response);
    }).catch((error) => {
        if (error instanceof error_1.AuthenticationError) {
            console.error('Authentication Error');
        }
        console.error(error);
        return new error_1.RequestError(error, { module: 'Summary' });
    });
}
exports.createSummaryFromText = createSummaryFromText;
;
function followUpSharedDocument(shareId) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.follow, { shareId }).then((response) => {
        if ((!response.success || response.error)
            && response.error?.message !== EXISTENT_SHARED_DOCUMENT_MESSAGE) {
            return new error_1.APIError(response.error?.message, { module: 'Summary' });
        }
        return response.data;
    }).catch((error) => {
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized ? 'Session Out' : undefined
        });
    });
}
exports.followUpSharedDocument = followUpSharedDocument;
;
function transcriptYoutubeVideo(youtubeUrl) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.transcript, { youtubeUrl }).then((response) => {
        if ((!response.success || response.error)) {
            return new error_1.APIError(response.error?.message);
        }
        return response.data;
    }).catch((error) => {
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized ? 'Session Out' : undefined
        });
    });
}
exports.transcriptYoutubeVideo = transcriptYoutubeVideo;
;
function scrapURL(webUrl) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.constants.scrap, { webUrl }).then((response) => {
        if (!response.headings || !response.text) {
            return new error_1.APIError('Invalid response from scraper');
        }
        return response.text;
    }).catch((error) => {
        if (error.response?.status === axios_1.HttpStatusCode.Unauthorized) {
            return new error_1.RequestError(error, { reason: 'Session Out' });
        }
        return new error_1.RequestError(error);
    });
}
exports.scrapURL = scrapURL;
function editDocumentTitle(documentJson, newTitle) {
    const updatedDocumentJson = { jsonUrl: documentJson.jsonPath, title: newTitle };
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.title, updatedDocumentJson).then((response) => {
        if (!response.success || response.error) {
            return new error_1.APIError(response.error?.message || 'Failed to edit document title');
        }
        return response;
    }).catch((error) => {
        if (error.response?.status === axios_1.HttpStatusCode.Unauthorized) {
            return new error_1.RequestError(error, { reason: 'Session Out' });
        }
        return new error_1.RequestError(error);
    });
}
exports.editDocumentTitle = editDocumentTitle;
function deleteDocument(fileId) {
    return interceptorsCached_1.cachedAuthCall
        .delete(`${apis_1.APP_CONSTANTS.documents.index}/${fileId}`)
        .then((response) => response.success ? response : new error_1.APIError(response.error.message))
        .catch((error) => new error_1.RequestError(error));
}
exports.deleteDocument = deleteDocument;
;
// Regenerate Images for Document
function regenerateImage(documentJson, newPrompt, styleString) {
    const { character_id, imageGenerationId, conversationId, newCharacterId } = documentJson;
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('payload', JSON.stringify({
        modelId: 'black-forest-labs/flux-dev',
        prompt: newPrompt ?? (0, document_1.getMarkdownContent)(documentJson.summaryResponse, 'Image Prompt'),
        styleString: styleString ?? 'An oil painting depicting',
        aspectRatio: '16:9',
        characterId: newCharacterId,
        conversationId,
        character_id,
        imageGenerationId,
    }));
    return (interceptorsCached_1.cachedAuthCall
        .post(apis_1.APP_CONSTANTS.documents.regenerateImage, formData, config).then((response) => (response.success ? response.data : new error_1.APIError(response.error?.message))).catch((error) => new error_1.RequestError(error)));
}
exports.regenerateImage = regenerateImage;
;
function uploadImage(image, documentJson) {
    const { conversationId, character_id, newCharacterId, imageGenerationId } = documentJson;
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('file', image);
    formData.append('payload', JSON.stringify({
        modelId: 'black-forest-labs/flux-schnell',
        prompt: '',
        styleString: 'An oil painting depicting',
        aspectRatio: '16:9',
        characterId: newCharacterId,
        conversationId,
        character_id,
        imageGenerationId,
    }));
    return (interceptorsCached_1.cachedAuthCall
        .post(apis_1.APP_CONSTANTS.documents.regenerateImage, formData, config).then((response) => (response.success ? response.data : new error_1.APIError(response.error?.message))).catch((error) => new error_1.RequestError(error)));
}
exports.uploadImage = uploadImage;
;
function getDocumentImages(imageApiDto) {
    const { documentName, prompt, modelId, styleString, force, imageGenerationId, documentId } = imageApiDto;
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const payload = {
        modelId: modelId || 'black-forest-labs/flux-schnell',
        prompt: prompt || `Document: ${documentName}`,
        styleString: styleString || 'An oil painting depicting',
        aspectRatio: '16:9',
        documentId,
        imageGenerationId,
        force,
    };
    return interceptorsCached_1.cachedAuthCall
        .post(apis_1.APP_CONSTANTS.documents.regenerateImage, payload, config)
        .then((response) => response.success ? response.data : new error_1.APIError(response.error?.message))
        .catch((error) => new error_1.RequestError(error));
}
exports.getDocumentImages = getDocumentImages;
;
function generateFiveMinutesAudio(document, onStream) {
    const payload = {
        text: (0, document_1.getFiveMinuteWrapContent)('summaryResponse' in document
            ? document.summaryResponse
            : document.message),
        jsonUrl: document.jsonPath,
        type: "wrap",
        title: 'title' in document ? document.title : document.fileName,
    };
    console.log("What I can't hear you?", payload);
    const handleStream = (stream) => {
        const audioData = new Uint8Array(stream);
        // const file = new File([audioData], Math.random().toString(), { type: 'audio/mpeg' })
        const blob = new Blob([audioData], { type: 'audio/mpeg' });
        onStream(blob);
    };
    return (0, streaming_1.stream)(apis_1.APP_CONSTANTS.documents.generateAudio, payload, handleStream);
}
exports.generateFiveMinutesAudio = generateFiveMinutesAudio;
;
