"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigation = exports.goBack = exports.popToPop = exports.pop = exports.push = exports.replace = exports.dispatch = exports.navigate = exports.navigationRef = void 0;
const React = __importStar(require("react"));
const native_1 = require("@react-navigation/native");
exports.navigationRef = React.createRef();
// export const navigation = navigationRef.current
function navigate(name, params) {
    console.log("Navigate it..", name, params);
    exports.navigationRef.current?.navigate(name, params);
    // cast to any to make it work
}
exports.navigate = navigate;
function dispatch(action) {
    exports.navigationRef.current?.dispatch(action);
}
exports.dispatch = dispatch;
function replace(name, params) {
    console.log("replace it..");
    exports.navigationRef.current?.dispatch(native_1.StackActions.replace(name, params));
}
exports.replace = replace;
function push(name, params) {
    console.log("push it..");
    exports.navigationRef.current?.dispatch(native_1.StackActions.push(name, params));
}
exports.push = push;
function pop(depth) {
    exports.navigationRef.current?.dispatch(native_1.StackActions.pop(depth));
}
exports.pop = pop;
function popToPop(name, params) {
    exports.navigationRef.current?.dispatch(native_1.CommonActions.reset({
        index: 1,
        routes: [
            { name: name }, //to go to initial stack screen
        ],
    }));
}
exports.popToPop = popToPop;
function goBack() {
    exports.navigationRef.current?.goBack();
}
exports.goBack = goBack;
exports.navigation = {
    navigate,
    dispatch,
    replace,
    // push,
    // popToPop,
    goBack,
};
