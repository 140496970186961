"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_CONSTANTS = void 0;
exports.APP_CONSTANTS = {
    auth: {
        register: "users/register",
        sync: "users/sync",
        login: "auth/login",
        checkUsername: "users/check",
        // profile: "me",
        userProfile: "users/me",
        users: "users",
        quota: "users/message/count",
        delete: "users/delete",
    },
    character: {
        index: "characters",
        characters: "characters",
        create: "characters/new",
        authorCharacters: "admin/characters/users",
        edit: "characters/edit",
        categories: "characters/categories",
        search: "characters/search?query=",
        generateCharacter: "characters/generate-details",
    },
    conversation: {
        index: "conversations",
        update: "conversations",
    },
    prompts: {
        get: "prompts",
        upload: "prompts",
    },
    messeges: {
        send: "messages/send",
        images: "messages/images",
        get: "messages",
    },
    discover: {
        configs: "admin/discover",
        categories: "categories",
        stats: 'messages/messages-count',
        characters: {
            popular: 'character-metadata',
            editor: 'character-metadata',
            users: 'admin/characters/users'
        },
        collections: 'collections',
        journeys: 'journeys',
        collectioByTitle: 'collections/title',
        gptModels: 'gpt-models'
        //https://mppuhfazgw.us-west-2.awsapprunner.com/api/v1/messages/messages-count
    },
    imageModel: 'image-models',
    constants: {
        'scrap': 'constants/web-scraper', // Endpoint for web scraping functionality
    },
    documents: {
        index: 'files',
        summaryJson: 'constants/doc-summary-json',
        createSummary: 'files/generate-openai-summary',
        createSummaryGemini: 'files/generate-assistant-summary',
        share: 'share',
        shareDetails: 'share/details',
        follow: 'share/follow-up',
        regenerateImage: 'files/images',
        generateAudio: 'files/audio',
        transcript: 'files/transcript',
        notes: 'notes',
        title: 'files/update-title',
    },
};
